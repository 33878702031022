export const ROUTE_LOADER_IDS = Object.freeze({
  ROOT_EC: "root-ec",
  ESTATE_SERVICES: "estate-services",
});

export const ROUTES = {
  ROOT: "/",
  ESTATE_SERVICES: "/estate-services",
  CALENDLY: "/estate-services/calendly",
  GET_STARTED: "/estate-services/get-started",
  CHECK_INHERITANCE_STATUS: "/estate-services/check-inheritance-status",
  DECEASED_PERSON_INFO: "/estate-services/deceased-person-information",
  INHERITOR_INFO: "/estate-services/inheritor-information",
  UPLOAD_DEATH_CERTIFICATE: "/estate-services/death-certificate-upload",
  UPLOAD_GOV_ID: "/estate-services/gov-id-upload",
  UPLOAD_WILL_TEST: "/estate-services/will-testament-upload",
  REVIEW_INFO: "/estate-services/review-info",
  SUBMITTED_INFO: "/estate-services/submitted-info",
  SUBMITTED_INFO_STATUS: "/estate-services/submitted-info-status",
  INHERITANCE_VAULT: "/estate-services/vault",
};

export const CONSTANTS = Object.freeze({
  VITE_APP_API_URL: import.meta.env.VITE_APP_API_URL,
  VITE_APP_BASE_DOMAIN: import.meta.env.VITE_APP_BASE_DOMAIN || "localhost:3000",
  VITE_APP_DEFAULT_FALLBACK_URL:
    import.meta.env.VITE_APP_DEFAULT_FALLBACK_URL || "https://www.trustribbon.com",
  VITE_AUTH_CLIENT_ISSUER_DOMAIN: import.meta.env.VITE_AUTH_CLIENT_ISSUER_DOMAIN,
  VITE_AUTH_CLIENT_ID: import.meta.env.VITE_AUTH_CLIENT_ID,
  VITE_AUTH_CLIENT_SECRET: import.meta.env.VITE_AUTH_CLIENT_SECRET,
  VITE_AUTH_CLIENT_AUDIENCE: import.meta.env.VITE_AUTH_CLIENT_AUDIENCE,
});

export const INSTITUTION_SUBDOMAINS = Object.freeze({
  ACUTX: "acutx",
  ALLIANCECU: "alliancecutx",
  ALOHAPACIFIC: "alohapacific",
  ALPINEBANK: "alpinebank",
  ANBBANK: "anbbank",
  APPLEFCU: "applefcu",
  ARLINGTON: "arlingtoncu",
  BANCOFCAL: "bancofcal",
  BANKIOWA: "bankiowa",
  BANKOFTHEJAMES: "bankofthejames",
  BANKOFTHESIERRA: "bankofthesierra",
  BAYPORTCU: "bayportcu",
  BLUERIDGEBANK: "mybrb",
  BLUESTONE: "bluestonefcu",
  CALCOASTCU: "calcoastcu",
  CENTRALWCU: "centralwcu",
  CFCU: "cfcu",
  CHRISTIANCOMMUNITY: "mycccu",
  CLARKCOUNTYCU: "ccculv",
  COMPASSCCU: "compassccu",
  CONGRESSIONAL: "congressionalfcu",
  CORNINGCU: "corningcu",
  CUOFAMERICA: "cuofamerica",
  CUTEXAS: "cutx",
  DELONE: "delone",
  DOLFCU: "dolfcu",
  EAGLECU: "eaglecu",
  EXCITECU: "excitecu",
  FAIRWINDS: "fairwinds",
  FIGFCU: "figfcu",
  FIRSTCITY: "firstcitycu",
  GOLDEN1: "golden1",
  HAPO: "hapo",
  IDBGLOBAL: "idbglobalfcu",
  ITCU: "itcu",
  JOHNSHOPIKINS: "jhfcu",
  LFCU: "lfcu",
  LOGIXBANKING: "logixbanking",
  MIDDLEFIELD: "middlefieldbank",
  MSUFCU: "msufcu",
  MYCFCU: "mycfcu",
  MYWSFCU: "mywsfcu",
  OAKVIEWBANK: "oakviewbank",
  ONENEVADA: "onenevada",
  ORANGECOMMUNITYCU: "myoccu",
  PENDLETON: "pcb",
  QUINCYCU: "qcu",
  RELIANTCU: "reliantcu",
  RIBBON: "ribbon",
  SECUMD: "secumd",
  SRPFCU: "srpfcu",
  SUMMITCU: "summitcu",
  TBANK: "tbank",
  TEACHERS: "teachersfcu",
  TEXASTRUSTCU: "texastrustcu",
  TOGETHERCU: "togethercu",
  TOWERFCU: "towerfcu",
  TROPICALFCU: "tropicalfcu",
  TRUESKY: "trueskycu",
  TRUMARK: "trumarkonline",
  TRUWEST: "truwest",
  TTCU: "ttcu",
  UNITYBANK: "unitybank",
  USCCREDITUNION: "usccreditunion",
  VACU: "vacu",
  VALLEYSTRONG: "valleystrong",
  VANTAGEWEST: "vantagewest",
  WESTCOMMUNITY: "westcommunitycu",
});
